/*!
 * Start Bootstrap - New Age v4.0.0-beta.2 (https://startbootstrap.com/template-overviews/new-age)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-new-age/blob/master/LICENSE)
 */

 @import './globalStyles/palette.scss';

 html,
 body {
   width: 100%;
   height: 100%; }
 
 body {
   font-family: 'Muli', 'Helvetica', 'Arial', 'sans-serif'; }
 
 a {
   color: $color3;
   -webkit-transition: all .35s;
   -moz-transition: all .35s;
   transition: all .35s; }
   a:hover, a:focus {
     color: $color3; }
 
 hr {
   max-width: 100px;
   margin: 25px auto 0;
   border-width: 1px;
   border-color: rgba(34, 34, 34, 0.1); }
 
 hr.light {
   border-color: white; }
 
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
   font-weight: 200;
   letter-spacing: 1px; }
 
 p {
   font-size: 18px;
   line-height: 1.5;
   margin-bottom: 20px; }
 
 section {
   padding: 100px 0; }
   section h2 {
     font-size: 50px; }
 
 
 .bg-primary {
   background: $color3;
   background: -webkit-linear-gradient($color3, darken($color3, 10%));
   background: linear-gradient($color3, darken($color3, 10%)); }
 
 .text-primary {
   color: $color3; }
 
 .no-gutter > [class*='col-'] {
   padding-right: 0;
   padding-left: 0; }
 
 .btn-outline {
   color: white;
   border: 1px solid;
   border-color: white; }
   .btn-outline:hover, .btn-outline:focus, .btn-outline:active, .btn-outline.active {
     color: white;
     border-color: $color3;
     background-color: $color3; }
 
 .btn {
   border-radius: 300px;
   font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
   letter-spacing: 2px;
   text-transform: uppercase; }
 
 .btn-xl {
   font-size: 11px;
   padding: 15px 45px; }
 