/* Coolors Exported Palette - coolors.co/171738-6000cc-2e1760-7180b9-dff3e4 */

/* HSL */
$color1: hsla(240%, 42%, 15%, 1);
$color2: hsla(268%, 100%, 40%, 1);
$color3: hsla(259%, 61%, 23%, 1);
$color4: hsla(228%, 34%, 58%, 1);
$color5: hsla(135%, 45%, 91%, 1);

/* RGB */
$color1: rgba(23, 23, 56, 1);
$color2: rgba(96, 0, 204, 1);
$color3: rgba(46, 23, 96, 1);
$color4: rgba(113, 128, 185, 1);
$color5: rgba(223, 243, 228, 1);